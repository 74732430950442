<template>
  <div class="row mx-0 mb-1 position-relative">
    <slot />
    <div class="col-12 p-0">
      <div :for="id" class="form-label" v-if="label" v-html="label" :class="{'required': required}"></div>
      <div class="custom-tel-input">
        <CustomInput
          ref="input"
          type="tel"
          :id="id"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :color="color"
          :size="size"
          :showError="showError"
          :hasError="hasError"
          :hasErrors="hasErrors"
          :theError="theError"
          :errors="errors"
          :modelValue="modelValue"
          @update:modelValue="onInput"
          :watchFocus="watchFocus"
          @checkFocus="notFocus"
        >
          <slot>Contact Number</slot>
        </CustomInput>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input'
import CustomInput from './CustomInput.vue'

export default defineComponent({
  name: 'vue3-tel-input-za',
  setup (props, { emit }) {
    const input = ref()

    const telInput = ref()
    const beenOutOfFocus = ref(false)
    const isValidNumber = ref(true)
    const preferredCountry = computed(() => {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return ['gb']
      } else {
        return ['za']
      }
    })
    onMounted(() => {
      const inputElement = input.value.inputElement
      if (props.placeholder) {
        inputElement.placeholder = props.placeholder
      }
      telInput.value = intlTelInput(inputElement, {
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js',
        preferredCountries: preferredCountry.value
        // your options
      })
    })

    const hasError = computed(() => {
      return props.isDirty || !isValidNumber.value
    })

    function onInput () {
      if (beenOutOfFocus.value === true) {
        const formattedNumber = telInput.value.getNumber()
        isValidNumber.value = telInput.value.isValidNumber()

        emit('update:modelValue', formattedNumber)
        emit('update:theError', isValidNumber.value)
      }
    }
    function notFocus () {
      const formattedNumber = telInput.value.getNumber()
      isValidNumber.value = telInput.value.isValidNumber()

      emit('update:modelValue', formattedNumber)
      emit('update:theError', isValidNumber.value)
      if (props.watchFocus) {
        beenOutOfFocus.value = true
        console.log('CUSTOME IN WATCH')
        emit('checkFocus')
      }
    }

    return {
      input,
      onInput,
      telInput,
      isValidNumber,
      hasError,
      notFocus
    }
  },
  props: {
    modelValue: {
      type: [String, Number]
    },
    isDirty: {
      default: false,
      type: Boolean
    },
    id: {
      type: String,
      default: Math.random().toString(20).substr(2, 6)
    },
    type: String,
    placeholder: String,
    label: String,
    size: String,
    color: String,
    errors: Array,
    hasErrors: Number,
    autocomplete: String,
    disabled: Boolean,
    theError: {
      default: false,
      type: Boolean
    },
    showError: {
      default: false,
      type: Boolean
    },
    required: {
      type: String,
      default: ''
    },
    watchFocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:theError', 'checkFocus'],
  components: {
    CustomInput
  }
})
</script>

<style scoped>

.iti__flag {
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/img/flags.png");
}
.form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: var(--green-color-dark);
    opacity: 0.9;
    cursor: not-allowed;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.outline {
    background-color: #fff;
    color: var(--normal-color);
    border: 1px solid var(--normal-color);
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border: 2px solid var(--green-color-light);
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .form-control.outline:focus {
    background-color: #fff;
    border: 1px solid var(--green-color-light);
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/img/flags@2x.png");
  }
}
</style>
