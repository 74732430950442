<template>
  <div class="custom-input">
    <div>
      <input
        :id="id"
        :type="type"
        :required="required"
        @input="onInput($event.target.value)"
        class="custom-input__input form-control medium"
        :class="[color, size, {error: (hasError || hasErrors > 0 || !theError || (errors && errors.length > 0)) && showError}]"
        :value="modelValue"
        ref="inputElement"
        :autocomplete="autocomplete"
        :disabled="disabled"
        @blur="notFocus()"
      />
    </div>
    <div class="custom-input__error-msg isError text-center" v-for="(error, index) of errors" :key="index" >
      {{ error.$message }}
    </div>
    <div class="custom-input__error-msg isError text-center" v-if="(hasError && errors && errors.length===0) || ( hasError && !errors ) && showError">
      Enter a valid number
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'custom-input',
  props: {
    id: {
      required: true,
      type: String
    },
    type: {
      default: 'text',
      type: String
    },
    required: {
      default: false,
      type: Boolean
    },
    modelValue: {
      type: [String, Number]
    },
    hasError: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    label: String,
    size: String,
    color: String,
    errors: Array,
    hasErrors: Number,
    autocomplete: String,
    disabled: Boolean,
    theError: {
      default: false,
      type: Boolean
    },
    watchFocus: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const inputElement = ref(null)
    function onInput (value) {
      this.$emit('update:modelValue', value)
    }
    function notFocus () {
      if (props.watchFocus) {
        this.$emit('checkFocus')
      }
    }

    return {
      inputElement,
      onInput,
      notFocus
    }
  },
  emits: ['update:modelValue', 'checkFocus']
})
</script>

<style scoped>
.iti {
  width: 100%
}
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: var(--green-color-dark);
    opacity: 0.9;
    cursor: not-allowed;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.outline {
    background-color: #fff;
    color: var(--normal-color);
    border: 1px solid var(--normal-color);
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border: 2px solid var(--green-color-light);
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .form-control.outline:focus {
    background-color: #fff;
    border: 1px solid var(--green-color-light);
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
</style>
